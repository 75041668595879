import React from "react"
import debounce from 'debounce'
import { fetchProjectVideo, updateProject } from "../../util/project_api_util"
import PageHeader from "../page_header"
import DownloadModal from "./download_modal_container"
import { withRouter } from "react-router-dom"
import EditProjectMedia from "./edit_project_media"
import NavbarContainer from "../navbar/navbar_container"
import FooterSection from "../pageComponents/FooterSection"
import { InView } from 'react-intersection-observer';

class MLSVideoShare extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
     media: {}
    }

    this.updatePredicate = this.updatePredicate.bind(this)
    this.changeVideoState = this.changeVideoState.bind(this)
    this.videoRef = React.createRef()
  }

  componentDidMount() {
    const mediaId = this.props.match.params.media_id
    const mls_code = this.props.match.params?.mls_code
    fetchProjectVideo(mediaId, mls_code).then(response => {
      const media = Object.values(response)[0]
      if (!media) {
        this.props.history.push("/")
      } else {
        this.setState({media: media})
      }
    })
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isLargeScreen: window.innerWidth > 750 });
  }

  changeVideoState(inView) {
    if (inView) {
      this.videoRef.current.play();
    } else {
      this.videoRef.current.pause();
    }
  }

  render() {
    const { media } = this.state
    const { currentUser } = this.props

    if (Object.values(media).length === 0){
      return <div></div> 
    }

    return (
      <div className="mediaShareNoUserView">
        {/* <NavbarContainer /> */}
        <div className="mediaShareMainDiv mediaShareMLS">
          <div className="editProjectSection editProjectPhotosSection mediaShareMLSVideo">
            <div className="propertyTourSectionVideo">
              <InView as="div" onChange={(inView) => this.changeVideoState(inView)}>
                <video controls muted playsInline ref={this.videoRef} preload="metadata">
                  <source type="video/mp4" src={`${media.url}`} /> 
                </video>
              </InView>
           </div>
          </div>
        </div>
        {/* {currentUser ? "" : <FooterSection displayLoginButtons={!this.props.currentUser} displayOrderNow={false} />} */}
      </div>
    )
  }
}

export default withRouter(MLSVideoShare)