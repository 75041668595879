import React from "react";
import debounce from 'debounce'
import { Route, Link, withRouter } from "react-router-dom";
import LocationComponent from "../location_component";
import ServicesSelectionComponent from "./services_selection_component_2";
import ServiceChoiceComponent from "./service_choice_component";
import PhotographerSelectionComponent from "../create_project/photographer_selection_component";
import OrderSummaryComponent from "../create_project/order_summary_component";
import CreateProjectModal from "../create_project/create_project_modal_container";
import AccountRequiredModal from "../create_project/account_required_modal";
import { fetchUserPromos } from "../../util/settings_api_util";
import ProjectCreatedModal from "../create_project/project_created_modal";
import smallMobileLogo from "../../../../assets/images/smallMobileLogo";
import ContractorCalendarModal from "../create_project/contractor_calendar_component_container";
import ChooseTimeModal from "../create_project/choose_time_modal";
import AddMoreServicesHomepageModal from "../create_project_home_page/add_more_services_homepage_modal";
import { fetchTimeSlots } from "../../util/settings_api_util";
import { fetchBundleGroups } from "../../util/bundle_api_utils";
import {formatDatetime} from "../../util/date_util";
import { fetchUnavailability } from "../../util/project_api_util";
import AddonSubServicesSelectionComponent from "./addon_subservices_selection_component"
import { fetchLocationSubServices } from "../../util/service_api_util";
import { detectTimezone } from "../../util/timezone_utils"

class CreateProjectMain2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      county: "",
      street: "",
      apartment: "",
      zipcode: "",
      state: "",
      city: "",
      latitude: "",
      longitude: "",
      formatted_address: "",
      note: "",
      sub_broker_client: "",
      sub_broker_client_info: {},
      client_role: "",
      user_emails: [],
      client_timezone: undefined,
      photographer: "",
      promos: [],
      promo_price: "",
      loading: true,
      bundleLoading: true,
      promo_name: "",
      currentlySelectedService: null,
      emails: [],
      package_attributes: {},
      selectedSubservices: {},
      cancelledPackages: {},
      appointments: {},
      selected_service: -1,
      specific_service: -1,
      selected_bundle: {},
      bundle_groups: [],
      main_service: {},
      previouslyChosen: {},
      discount: 0,
      waiting: false,
      modalOpen: false,
      contractorModalOpen: false,
      chooseTimeModalOpen: false,
      goingBackToAddress: false,
      bundleSelected: false,
      alacartSelected: false,
      addonSubServices: false,
      account_credit: this.props.currentUser?.account_credit?.amount,
      account_credit_id: "",
      contractorSearch: "",
      contractorDateSearch: "",
      contractorTimeSearch: "",
      twilight_time: "",
      firm: {},
      project: {},
      users: {},
      errors: [],
      propertyErrors: [],
      summaryErrors: [],
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      page: 0,
      coupon: {},
      location_pricing: {},
      detected_timezone: ""
    }

    this.handler = this.handler.bind(this)
    this.setAppointmentTime = this.setAppointmentTime.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.previewOrder = this.previewOrder.bind(this)
    this.deleteSubService = this.deleteSubService.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.debounceFetchContractors = debounce((service, zip, search, date, time, services, appointments, timezone) => this.props.fetchContractors(service, zip, search, date, time, services, appointments, timezone), 500)
    this.debouceScrollTo = debounce((selector) => this.scrollTo(selector), 200)
  }

  componentDidMount() {
    this.props.fetchServices().then(() => {
      if (this.props.currentUser.roles.broker || (this.props.currentUser.roles.sub_broker && this.props.currentUser.firm?.broker_charge)) {
        fetchUserPromos(this.props.currentUser.id).then(promos => this.setState({ promos: Object.values(promos) }))
      }
      this.props.fetchCurrentUser(this.props.currentUser.id).then(user => {
        if (!user.user.firm?.broker_charge) {
          this.setState({ account_credit: user.user?.account_credit?.amount, user_emails: user.user?.invites, loading: false})
        } else {
          this.setState({ account_credit: null, user_emails: user.user?.invites, loading: false })
        }
      })
    })

    fetchBundleGroups().then(bundle_groups => this.setState({ bundle_groups: bundle_groups, bundleLoading: false }))

    fetchTimeSlots(true).then(time_slots => {
      this.setState({twilight_time: time_slots})
    })

    this.unblock = this.props.history.block((location, action) => {
      if (action === 'POP') {
        if (this.state.page > 0) {
          this.handlePrevious();
        } 
        return false;
      }
      return true;
    });

    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    if (this.unblock) {
      this.unblock();
    }
  }


  componentDidUpdate(_, prevState) {
    if (prevState.county !== this.state.county){
      if (this.state.county != ""){
        fetchLocationSubServices(this.state.county).then(location_pricing => {
          this.setState({location_pricing: location_pricing})
        })
      } else {
        this.setState({location_pricing: {}})
      }
    }

    if (prevState.contractorSearch !== this.state.contractorSearch) {
      this.debounceFetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone)
    }

    if (((prevState.contractorDateSearch !== this.state.contractorDateSearch || prevState.contractorTimeSearch !== this.state.contractorTimeSearch) && !this.state.waiting)) {
      this.setState({ waiting: true })
      this.props.fetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone).then(() => { this.setState({ waiting: false }) })
    }

    if (prevState.sub_broker_client !== this.state.sub_broker_client && (this.state.client_role === "broker" || this.state.client_role === "sub_broker")) {
      if (this.state.client_role === "sub_broker" && !this.state.firm?.broker_charge) {
        this.setState({ promos: [] })
      } else {
        fetchUserPromos(this.state.sub_broker_client).then(promos => this.setState({ promos: Object.values(promos) }))
      }
    } else if (prevState.sub_broker_client !== this.state.sub_broker_client && (this.state.client_role != "broker" && this.state.client_role != "sub_broker")) {
      this.setState({ promos: [] })
    }
  }

  scrollTo(selector) {
    const element = document.querySelector(selector)
    if (element) element.scrollIntoView()
  }

  anyServiceSelected() {
    return Object.keys(this.state.selectedSubservices).length > 0
  }

  handleNext() {

    this.setState({ errors: [] })

    let errors = []

    if (this.state.page === 0) {
      if (!this.state.alacartSelected && !this.state.bundleSelected) {
        errors.push("Please select an option before continuing")
      }
    }

    if (this.state.page === 1) {
      // if (this.state.street === "") {
      //   errors.push([1, "Street can't be blank."])
      // }

      if (this.state.street === "") {
        errors.push([1, "Address must be selected from dropdown"])
      }

      if (this.state.city === "") {
        errors.push([2, "City name can't be blank"])
      }

      if (this.state.state === "") {
        errors.push([3, "State can't be blank"])
      }

      if (this.state.zipcode === "") {
        errors.push([4, "Zipcode can't be blank"])
      }
    }
    if (this.state.page === 2) {
      if ((!this.anyServiceSelected() && this.state.alacartSelected) || Object.values(this.state.package_attributes).length === 0) {
        errors.push("Please select a service before continuing")
      }
    }

    if (this.state.page === 4) {
      if (!this.state.appointments[this.state.selected_service]) {
        errors.push("You must select an appointment time before continuing")
      }
    }

    if (errors.length > 0 && this.state.page === 1) {
      this.setState({ propertyErrors: errors })
    } else if (errors.length > 0) {
      this.setState({ summaryErrors: errors })
    }
    else {
      if (this.state.page === 1 && this.state.goingBackToAddress) {
        this.setState({ page: 5, errors: [], summaryErrors: [], propertyErrors: [] })
      } 
      else if (this.state.page === 1) {
        detectTimezone(this.state.latitude, this.state.longitude).then(detected_timezone => {
          fetchTimeSlots(true, detected_timezone).then(time_slots => {
            this.setState({ twilight_time: time_slots, detected_timezone: detected_timezone })
          })
        })
        this.setState({ page: 2, errors: [], summaryErrors: [], propertyErrors: [] })
      }
      else {
        if (this.state.page === 4 || this.state.page === 2){
          if (Object.values(this.state.appointments).length === Object.values(this.state.package_attributes).length){
            this.setState({ page: this.state.page === 2 ? this.state.page + 3 : this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [], currentlySelectedService: null, selected_service: -1 })
          }
          else{
            let selected_service = "";
            let appointment_time = "";
            if (Object.values(this.state.package_attributes).length - Object.values(this.state.appointments).length > 1){
              selected_service = parseInt(Object.keys(this.state.package_attributes).find(n => !this.props.services[n].twilight && !Object.keys(this.state.appointments).includes(n)))
            } else {
              selected_service =parseInt(Object.keys(this.state.package_attributes).find(n => !Object.keys(this.state.appointments).includes(n)))
            }
            if (Object.values(this.state.appointments).length > 0) {
              appointment_time = Object.values(this.state.appointments).find(n => !this.props.services[n.service_id].twilight)?.appointment_time ? new Date(Object.values(this.state.appointments).find(n => !this.props.services[n.service_id].twilight)?.appointment_time) : ""
            }
            let contractorDateSearch = appointment_time || "";
            let contractorTimeSearch = appointment_time ? appointment_time.getHours() + (appointment_time.getMinutes() / 60) : ""
            if (this.props.services[selected_service].twilight && appointment_time){
              const weekday = formatDatetime(appointment_time, 'dddd')
              contractorTimeSearch = Object.keys(this.state.twilight_time[weekday])[0]
            }
            this.setState({ selected_service: selected_service, waiting: true, errors: [], summaryErrors: [], propertyErrors: [], contractorDateSearch: contractorDateSearch, contractorTimeSearch: contractorTimeSearch })
            this.props.fetchContractors(selected_service, this.state.zipcode, this.state.contractorSearch, contractorDateSearch, contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone).then((contractors) => { 
              if (Object.values(contractors.contractors).length === 0){
                this.setState({contractorDateSearch: "", contractorTimeSearch: "", waiting: false})
              } else {
                this.setState({ waiting: false }) 
              }
            })

            if (this.state.page === 2) {
              this.setState({ page: 3 })
            }
          }
        } else {
          if (this.state.page < 5){
            this.setState({ page: this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [] })
          }
        }
        this.debouceScrollTo('#scrollToHere')
      }
    }
  }

  handlePrevious(e) {
    if (e){
      e.preventDefault()
    }

    if (this.state.page === 4 && (this.state.alacartSelected || (this.state.bundleSelected && !this.state.addonSubServices))){
      this.setState({ page: this.state.page - 2, errors: [], summaryErrors: [], propertyErrors: [], selected_service: -1, currentlySelectedService: null })
    } else if (this.state.page === 4 && this.state.alacartSelected) {
      this.setState({ page: 2, errors: [], summaryErrors: [], propertyErrors: [], selected_service: -1, currentlySelectedService: null })
    } else if (this.state.page === 2 && this.state.currentlySelectedService){
      this.setState({errors: [], selected_service: -1, currentlySelectedService: null, propertyErrors: [], summaryErrors: []})
    } else {
      this.setState({ page: this.state.page - 1, errors: [], summaryErrors: [], propertyErrors: [] })
    }
    this.debouceScrollTo('#scrollToHere')
  }

  deleteSubService(sub_service) {
    let newState = Object.assign({}, this.state.package_attributes);
    let newStateSubservices = Object.assign({}, this.state.selectedSubservices);
    let newStateAppointments = Object.assign({}, this.state.appointments);

    delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
    delete newStateSubservices[sub_service.id]

    if (Object.values(newState[sub_service.service_id]["package_sub_services_attributes"]).length === 0) {
      delete newStateAppointments[sub_service.service_id]
      delete newState[sub_service.service_id]
    }

    this.setState({ package_attributes: newState, selectedSubservices: newStateSubservices, appointments: newStateAppointments })
  }

  previewOrder(discount, promo_price, promo_name) {
    let errors = []

    if (this.state.street === "") {
      errors.push([1, "Street can't be blank."])
    }

    if (this.state.city === "") {
      errors.push([2, "City name can't be blank"])
    }

    if (this.state.state === "") {
      errors.push([3, "State can't be blank"])
    }

    if (this.state.zipcode === "") {
      errors.push([4, "Zipcode can't be blank"])
    } else if (this.state.zipcode.length != 5) {
      errors.push([4, "Zipcode must be 5 digits"])
    }

    if (errors.length === 0) {
      this.setState({ promo_price: promo_price, promo_name: promo_name, discount: discount, modalOpen: true })
    } else {
      this.setState({ propertyErrors: errors, summaryErrors: ["Please fill out required fields above."] })
    }
  }

  submitOrder() {
    fetchUnavailability(Object.values(this.state.appointments), this.state.detected_timezone, this.state.street).then((unavailable_appointments) => {
      if (unavailable_appointments.length > 0){
        let newStateAppointments = this.state.appointments
        let newState = this.state.package_attributes
        unavailable_appointments.forEach((unavailable) => {
          delete newStateAppointments[unavailable]
          delete newState[unavailable]["appointment_time"]
          delete newState[unavailable]["contractor_id"]
        })
        this.props.showToast("Photographer is no longer available at this appointment time")
        this.setState({package_attributes: newState, appointments: newStateAppointments, page: 4, modalOpen: false })
      } else {
        let data = {}
        let address_attributes = {}
        let invites = this.state.emails.toString()
        
        if (this.state.discount > 0 && this.state.account_credit) {
          let coupon_info = { coupon_id: this.state.sub_broker_client ? this.state.account_credit_id : this.props.currentUser?.account_credit?.id, coupon_amount: parseInt(this.state.account_credit) - this.state.discount, coupon_discount_amount: this.state.discount }
          address_attributes = { formatted_address: this.state.formatted_address, county: this.state.county, apartment_number: this.state.apartment, city: this.state.city, region: this.state.state, street1: this.state.street, zip_code: this.state.zipcode, longitude: this.state.longitude, latitude: this.state.latitude }
          data = { coupon_info: coupon_info, client_id: this.state.sub_broker_client ? this.state.sub_broker_client : this.props.currentUser.id, promo_price: this.state.promo_price, address_attributes: address_attributes, packages_attributes: this.state.package_attributes, notes: this.state.note, invites: invites, broker_booked: this.props.currentUser.roles.broker }
        } else {
          address_attributes = { formatted_address: this.state.formatted_address, county: this.state.county, apartment_number: this.state.apartment, city: this.state.city, region: this.state.state, street1: this.state.street, zip_code: this.state.zipcode, longitude: this.state.longitude, latitude: this.state.latitude }
          data = { client_id: this.state.sub_broker_client ? this.state.sub_broker_client : this.props.currentUser.id, promo_price: this.state.promo_price, address_attributes: address_attributes, packages_attributes: this.state.package_attributes, notes: this.state.note, invites: invites, broker_booked: this.props.currentUser.roles.broker }
        }

        if (this.state.coupon?.id){
          data["discount_coupon_code"] = this.state.coupon.code
        }

        if (this.state.selected_bundle?.id){
          let bundle_price = 0
          let total_price = 0
          let bundleSubserviceIds = this.state.selected_bundle?.sub_services?.map(subservice => subservice.id) || [];

          Object.values(this.state.selectedSubservices).filter(sub_service => !bundleSubserviceIds.includes(sub_service.id)).forEach(subservice => {
            total_price += parseInt(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
          })

          this.state.selected_bundle.sub_services.forEach(subservice => {
            bundle_price += parseFloat(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
          })
          bundle_price = bundle_price * (1 - (this.state.selected_bundle.percent_discount / 100))


          total_price += Math.ceil(bundle_price)


          if (this.state.coupon?.id) {
            if (this.state.coupon.percentage) {
              total_price -= parseInt(total_price * this.state.coupon.amount / 100)
            } else {
              if (this.state.coupon.amount > price) {
                total_price = 0
              } else {
                total_price -= parseInt(this.state.coupon.amount)
              }
            }
          }

          data["discount_bundle_id"] = this.state.selected_bundle.id
          data["bundle_price"] = total_price
        }

        this.props.submitOrder(data).then(({ project }) => {
          if (project.errors) {
            this.setState({ modalOpen: false })
            this.setState({ errors: project.errors })
            window.scrollTo({ top: 0, behavior: 'smooth' })
          } else {
            this.setState({ page: 6, project: Object.values(project)[0] })
            if (this.state.discount > 0 && this.state.account_credit) {
              this.props.fetchCurrentUser(this.props.currentUser.id)
            }
          }
        })
      }
    })
  }

  handler(newState) {
    return new Promise((resolve, reject) => {
    if (this.state.propertyErrors.length > 0) {
      let errors = []

      const currentState = { ...this.state, ...newState }

      if (currentState.street === "") {
        errors.push([1, "Street can't be blank."])
      }

      if (currentState.city === "") {
        errors.push([2, "City name can't be blank"])
      }

      if (currentState.state === "") {
        errors.push([3, "State can't be blank"])
      }

      if (currentState.zipcode === "") {
        errors.push([4, "Zipcode can't be blank"])
      }

      let combinedState = Object.assign(newState, { propertyErrors: errors, summaryErrors: errors.length > 0 ? ["Please fill out required fields above."] : [] })
      this.setState(combinedState, () => {
        resolve();
      })
    } else {
      this.setState(newState, () => {
        resolve();
      })
    }})
  }

  setAppointmentTime(date, contractor) {
    let newState = this.state.package_attributes
    let newAppointmentState = this.state.appointments
    let newMainService = this.state.main_service
    let main = true;
    let contractor_service_ids = {}

    contractor.services.forEach(service => {
      contractor_service_ids[service.id] = true
    })

    Object.values(this.state.appointments).forEach(appointment => {
      if (appointment.contractor.id === this.state.appointments[this.state.selected_service]?.contractor?.id && appointment.appointment_time === this.state.appointments[this.state.selected_service]?.appointment_time && appointment.service_id != this.state.selected_service) {
        newAppointmentState[appointment.service_id] = { service_id: appointment.service_id, contractor: contractor, appointment_time: date }
        newState[appointment.service_id]["appointment_time"] = date
        newState[appointment.service_id]["contractor_id"] = contractor.id
        newState[appointment.service_id]["service_id"] = appointment.service_id
      }
    })

    Object.keys(this.state.package_attributes).forEach(package_service_id => {
      if (contractor_service_ids[package_service_id] && !this.props.services[package_service_id].twilight && !this.props.services[this.state.selected_service].twilight){
        if (!this.state.appointments[package_service_id]){
          newAppointmentState[package_service_id] = { service_id: package_service_id, contractor: contractor, appointment_time: date }
          newState[package_service_id]["appointment_time"] = date
          newState[package_service_id]["contractor_id"] = contractor.id
          newState[package_service_id]["service_id"] = package_service_id
        }
      }
    })

    if (main === true && !this.props.services[this.state.selected_service].twilight) {
      newMainService[contractor.id] = this.state.selected_service
    }

    newAppointmentState[this.state.selected_service] = { service_id: this.state.selected_service, contractor: contractor, appointment_time: date }

    newState[this.state.selected_service]["appointment_time"] = date
    newState[this.state.selected_service]["contractor_id"] = contractor.id
    newState[this.state.selected_service]["service_id"] = this.state.selected_service

    this.setState({ package_attributes: newState, appointments: newAppointmentState, main_service: newMainService, summaryErrors: [] })
  }

  render() {

    let icon = ""

    // let icon = <div key="2" onClick={() => this.props.history.goBack()}><i className="fas fa-arrow-left mainIcon createProjectIcon"></i></div>


    let modal = ""

    if (this.state.modalOpen && this.props.currentUser) {
      modal = <CreateProjectModal changeParentState={this.handler} location_pricing={this.state.location_pricing} coupon={this.state.coupon} selected_bundle={this.state.selected_bundle} promo_price={this.state.promo_price} firm={this.state.firm} promo_name={this.state.promo_name} sub_broker_client={this.state.sub_broker_client} client_role={this.state.client_role} street={this.state.street} city={this.state.city} state={this.state.state} formatted_address={this.state.formatted_address} apartment={this.state.apartment} zipcode={this.state.zipcode} discount={this.state.discount} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} isLargeScreen={this.props.isLargeScreen} appointments={this.state.appointments} submitOrder={this.submitOrder} client_timezone={this.state.client_timezone}  />
    } else if (this.state.modalOpen) {
      modal = <AccountRequiredModal changeParentState={this.handler} />
    } else if (this.state.contractorModalOpen) {
      modal = <ContractorCalendarModal photographer={this.state.photographer}
        setAppointmentTime={this.setAppointmentTime}
        appointments={this.state.appointments}
        selectedService={this.state.selected_service}
        detected_timezone={this.state.detected_timezone}
        client_timezone={this.state.client_timezone}
        street={this.state.street}
        changeSelected={() => this.setState({ contractorModalOpen: false })}
        isLargeScreen={this.props.isLargeScreen} />
    } else if (this.state.chooseTimeModalOpen) {
      modal = <ChooseTimeModal changeParentState={this.handler} currentUser={this.props.currentUser} detected_timezone={this.state.detected_timezone || this.state.client_timezone || this.props.currentUser.timezone} selectedService={this.state.selected_service} appointments={this.state.appointments} package_attributes={this.state.package_attributes} time_slots={this.props.services[this.state.selected_service]?.twilight ? this.state.twilight_time : this.state.time_slots} contractorDateSearch={this.state.contractorDateSearch} contractorTimeSearch={this.state.contractorTimeSearch} />
    }

    let component = "";
    let componentClosed1 = "";
    let componentClosed2 = "";
    let componentClosed6 = "";
    let componentClosed3 = "";
    let componentClosed4 = "";
    let componentClosed5 = "";
    let buttons = "";
    let button2 = "";

    let order_summary = <OrderSummaryComponent changeParentState={this.handler} location_pricing={this.state.location_pricing} user_emails={this.state.user_emails} users={this.state.users} assigned_user={this.state.sub_broker_client} sub_broker_client_info={this.state.sub_broker_client_info} coupon={this.state.coupon} page={this.state.page} emails={this.state.emails} note={this.state.note} services={this.props.services} promos={this.state.promos} package_attributes={this.state.package_attributes} deleteSubService={this.deleteSubService} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} appointments={this.state.appointments} previewOrder={this.previewOrder} currentUser={this.props.currentUser} formatted_address={this.state.formatted_address} street={this.state.street} city={this.state.city} state={this.state.state} apartment={this.state.apartment} zipcode={this.state.zipcode} selected_bundle={this.state.selected_bundle} sub_broker_client={this.state.sub_broker_client} client_timezone={this.state.client_timezone} />
    if (this.state.page === 0) {
      componentClosed1 = ""
      componentClosed2 = ""
      componentClosed3 = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} page={this.state.page} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed4 = <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService} minimize={true} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} />
      componentClosed5 = <PhotographerSelectionComponent detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} changeParentState={this.handler} createProjectVersion2={true} contractorTimeSearch={this.state.contractorTimeSearch} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} contractorDateSearch={this.state.contractorDateSearch} previouslyChosen={this.state.previouslyChosen} page={this.state.page} waiting={this.state.waiting} minimize={true} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} currentUser={this.props.currentUser} />
      component = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} />
      buttons = <div className="signupFormButtons">
        {/* <div className="signupFormNext" onClick={this.handleNext}>Next Step</div> */}
      </div>
    } else if (this.state.page === 1) {
      componentClosed1 = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} minimize={true} />
      component = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} minimize={false} page={this.state.page} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed3 = <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService} minimize={true} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} />
      componentClosed4 = <PhotographerSelectionComponent changeParentState={this.handler} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} currentUser={this.props.currentUser} createProjectVersion2={true} contractorTimeSearch={this.state.contractorTimeSearch} contractorDateSearch={this.state.contractorDateSearch} previouslyChosen={this.state.previouslyChosen} minimize={true} page={this.state.page} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} waiting={this.state.waiting} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>

      if (this.state.goingBackToAddress) {
        buttons = <div className="signupFormButtons">
          <div className="signupFormNext orderSummaryButton" onClick={this.handleNext}>Order Summary</div>
        </div>
      }
    } else if (this.state.page === 2) {
      componentClosed1 = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} minimize={true} />
      componentClosed2 = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} page={this.state.page} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed4 = <PhotographerSelectionComponent changeParentState={this.handler} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} currentUser={this.props.currentUser} createProjectVersion2={true} contractorTimeSearch={this.state.contractorTimeSearch} contractorDateSearch={this.state.contractorDateSearch} previouslyChosen={this.state.previouslyChosen} page={this.state.page} waiting={this.state.waiting} minimize={true} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
      component = <ServicesSelectionComponent changeParentState={this.handler} loading={this.state.loading} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService}  bundleLoading={this.state.bundleLoading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} />
      buttons = <div className="signupFormButtons">
        {Object.keys(this.state.package_attributes).length > 0 && Object.keys(this.state.package_attributes).length - Object.keys(this.state.appointments).length === 0 && !this.state.currentlySelectedService ? <div className="signupFormPrevious orderSummaryButton" onClick={() => this.setState({ page: 5, summaryErrors: [] })}>Order Summary</div> : <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>}
        <div className="signupFormNext" id="bundleScroll" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 3){
      if (this.state.alacartSelected){
        componentClosed1 = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} minimize={true} />
        componentClosed2 = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} page={this.state.page} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
        component = <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} loading={this.state.loading} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService} bundleLoading={this.state.bundleLoading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} />
        buttons = ""
        modal = <AddMoreServicesHomepageModal handleNext={this.handleNext} changeParentState={this.handler} services={Object.values(this.props.services)} orderedPackages={this.state.package_attributes} />
      } else {
        componentClosed1 = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} minimize={true} />
        componentClosed2 = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} page={this.state.page} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
        componentClosed4 = <PhotographerSelectionComponent changeParentState={this.handler} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} currentUser={this.props.currentUser} createProjectVersion2={true} contractorTimeSearch={this.state.contractorTimeSearch} contractorDateSearch={this.state.contractorDateSearch} previouslyChosen={this.state.previouslyChosen} page={this.state.page} waiting={this.state.waiting} minimize={true} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
        component = <AddonSubServicesSelectionComponent addonSubservices={this.state.addonSubServices} addonServices={true} location_pricing={this.state.location_pricing} changeParentState={this.handler} loading={this.state.loading} bundleLoading={this.state.bundleLoading} bundle_groups={this.state.bundle_groups} selected_bundle={this.state.selected_bundle} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} page={this.state.page} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
        buttons = <div className="signupFormButtons">
          {Object.keys(this.state.package_attributes).length > 0 && Object.keys(this.state.package_attributes).length - Object.keys(this.state.appointments).length === 0 ? <div className="signupFormPrevious orderSummaryButton" onClick={() => this.setState({ page: 5, summaryErrors: [] })}>Order Summary</div> : <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>}
          <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
        </div>
      }
    } else if (this.state.page === 4) {
      componentClosed1 = <ServiceChoiceComponent changeParentState={this.handler} handleNext={this.handleNext} page={this.state.page} alacartSelected={this.state.alacartSelected} bundleSelected={this.state.bundleSelected} summaryErrors={this.state.summaryErrors} minimize={true} />
      componentClosed2 = <LocationComponent changeParentState={this.handler} createProjectLoggedIn={true} page={this.state.page} minimize={true} formatted_address={this.state.formatted_address} street={this.state.street} errors={this.state.propertyErrors} apartment={this.state.apartment} zipcode={this.state.zipcode} state={this.state.state} city={this.state.city} />
      componentClosed6 = <ServicesSelectionComponent changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} location_pricing={this.state.location_pricing} currentlySelectedService={this.state.currentlySelectedService}  page={this.state.page} minimize={true} selectedSubservices={this.state.selectedSubservices} summaryErrors={this.state.summaryErrors} specific_service={this.state.specific_service} zipcode={this.state.zipcode} cancelledPackages={this.state.cancelledPackages} selected_service={this.state.selected_service} services={this.props.services} package_attributes={this.state.package_attributes} appointments={this.state.appointments} />
      component = <PhotographerSelectionComponent handleNext={this.handleNext} contractorTimeSearch={this.state.contractorTimeSearch} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} currentUser={this.props.currentUser} createProjectVersion2={true} contractorDateSearch={this.state.contractorDateSearch} changeParentState={this.handler} previouslyChosen={this.state.previouslyChosen} page={this.state.page} waiting={this.state.waiting} summaryErrors={this.state.summaryErrors} included_services={this.props.services} main_service={this.state.main_service} zipcode={this.state.zipcode} street={this.state.street} services={this.state.package_attributes} setAppointmentTime={this.setAppointmentTime} selectedService={this.state.selected_service} contractors={this.state.waiting ? {} : this.props.contractors} appointments={this.state.appointments} isLargeScreen={this.props.isLargeScreen} />
      buttons = <div className="signupFormButtons">
        <div className="signupFormPrevious" onClick={this.handlePrevious}>Prev Step</div>
        <div className="signupFormNext" onClick={this.handleNext}>Next Step</div>
      </div>
    } else if (this.state.page === 5) {
      order_summary = ""
      component = <OrderSummaryComponent changeParentState={this.handler} location_pricing={this.state.location_pricing} user_emails={this.state.user_emails} users={this.state.users} sub_broker_client={this.state.sub_broker_client} sub_broker_client_info={this.state.sub_broker_client_info} assigned_user={this.state.sub_broker_client} coupon={this.state.coupon} checkout={true} emails={this.state.emails} note={this.state.note} page={this.state.page} services={this.props.services} errors={this.state.summaryErrors} promos={this.state.promos} package_attributes={this.state.package_attributes} deleteSubService={this.deleteSubService} account_credit={this.state.account_credit} subservices={this.state.selectedSubservices} appointments={this.state.appointments} previewOrder={this.previewOrder} currentUser={this.props.currentUser} formatted_address={this.state.formatted_address} street={this.state.street} city={this.state.city} state={this.state.state} apartment={this.state.apartment} zipcode={this.state.zipcode} selected_bundle={this.state.selected_bundle} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone} />
      buttons = ""
    } else if (this.state.page === 6) {
      component = ""
      modal = <ProjectCreatedModal project={this.state.project} changeParentState={this.handler} detected_timezone={this.state.detected_timezone} client_timezone={this.state.client_timezone}/>
    }

    return (
      <div className="createProjectMainContainer">
        {modal}
        {/* <div className="settingsPageHeading createProjectPageHeading">
          {icon}
          <span className="dashboardSubHeading">{projectText}</span>
        </div> */}

        <div className="projectsIndexPageHeading createProjectsIndexPageHeading">
          <div className="dashboardSubHeading">Create Photoshoot</div>
          <div className="mobileLogo"><img src={smallMobileLogo} /></div>
        </div>

        {this.state.errors.length > 0 && (
          <div className="alert alert-danger mt-4">{this.state.errors.join('. ')}</div>
        )}

        <div className="createProjectGroupings">
          <div className="createProjectGroup1">
            {componentClosed1}
            {componentClosed2}
            {componentClosed6}
            <div>
              <div id="scrollToHere"></div>
            </div>
            {component}
            {buttons}
            {componentClosed3}
            {componentClosed4}
            {componentClosed5}
          </div>
          <div className="createProjectGroup2">
            {order_summary}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(CreateProjectMain2);