
import { fetchNotifications, updateNotificationsToRead } from "../../util/notification_utils"
import { withRouter } from "react-router-dom"
import { showToast } from "../../actions/toast_actions";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../assets/images/closeIconBlack";
import NotificationItem from "./notification_item";

const NotificationsModal = (props) => {
  const [notifications, setNotifications] = useState([])
  const [notificationLimit, setNotificationLimit] = useState(10)


  useEffect(() => {
    fetchNotifications(notificationLimit).then(notifications => setNotifications(notifications))
  }, [notificationLimit, props.unread_notification_count])

  useEffect(() => {
    if (props.modalOpen){
      document.body.style.overflow = 'hidden';
    }
  }, [props.modalOpen]);

  const handleClose = () => {
    document.body.style.overflow = 'unset';
    markAsRead(notifications);
    props.closeModal()
  };

  const markAsRead = (notificationsToMark) => {
    let ids = []

    if (props.currentUser.roles.admin){
      ids = notificationsToMark.filter(notification => !notification.global && notification.user_id === props.currentUser.id).map((notification) => notification.id);
    } else {
      ids = notificationsToMark.filter(notification => !notification.global).map((notification) => notification.id);
    }

    updateNotificationsToRead(ids).then(() => {
      const updatedNotifications = notifications.map((notification) => {
        if (ids.includes(notification.id)) {
          return { ...notification, read: true };
        }
        return notification;
      });
      setNotifications(updatedNotifications);
      props.fetchNotificationCount(props.currentUser.id)
    });
  };

  const notificationLis = notifications.map(notification => <NotificationItem notification={notification} handleClose={handleClose}/>)

  return <div className={`modal-background ${props.modalOpen ? '' : 'hidden'}`} onClick={(handleClose)}>
    <div className={`notificationModalDiv ${props.modalOpen ? 'open' : ''}`} onClick={(event) => event.stopPropagation()}>
        <div className="closeIconMobileNavModal"><img className="closeIconMobileNavModalImage" onClick={handleClose} src={closeIcon} /></div>
        <div className="notificationModalHeading">Alerts ({props.currentUser.unread_notifications})</div>
        <div className="notificationGroupDiv">
          {notificationLis}
        {notifications.length > 0 && notifications.length % 10 === 0 && <button onClick={() => setNotificationLimit(notificationLimit + 10)} className="notificationsButton">View More</button>}
        </div>
        </div>
    </div>
}

const msp = (state) => ({})

const mdp = (dispatch) => ({
  showToast: (message) => dispatch(showToast(message))
});

export default withRouter(connect(msp, mdp)(NotificationsModal));