import React from "react"
import debounce from 'debounce'
import { withRouter } from "react-router-dom";
import ServicesSelectionComponent from "../create_project_2/services_selection_component_2";
import {fetchLocationSubServices, fetchServices} from "../../util/service_api_util"
import PhotographerSelectionComponent from "../create_project/photographer_selection_component"
import {fetchUserPromos} from "../../util/settings_api_util"
import AddMoreServicesModal from "../create_project_home_page/add_more_services_homepage_modal"
import OrderSummaryComponent from "../update_project/order_summary_component"
import ProjectUpdatedModal from "../update_project/project_updated_modal"
import UpdateProjectModal from "../update_project/update_project_modal_container"
import {deleteProject, updateProject} from "../../util/project_api_util"
import ContractorCalendar from "../create_project/contractor_calendar_component_container"
import ChooseTimeModal from "../create_project/choose_time_modal";
import { fetchUnavailability } from "../../util/project_api_util";
import { formatDatetime } from "../../util/date_util";
import { railsToJsTimezoneMap } from "../../util/timezone_utils";
import moment from 'moment'
import 'moment-timezone';
import { detectTimezone } from "../../util/timezone_utils";

class AddServiceModal extends React.Component {
  constructor(props) {
    super(props)
    const {project} = props

    this.state = {
      page: 2,
      selectedContractor: null,
      summaryErrors: [],
      errors: [],
      selected_service: -1,
      specific_service: -1,
      zipcode: '',
      street: '',
      county: '',
      latitude: '',
      longitude: '',
      cancelledPackages: [],
      selectedSubservices: [],
      services: project.services,
      package_attributes: [],
      appointments: [],
      contractorSearch: '',
      contractorDateSearch: "",
      contractorTimeSearch: "",
      account_credit: project.client.account_credit?.amount,
      allServices: [],
      promos: [],
      firm: {},
      previouslyChosen: {},
      subModal: null,
      addMoreModal: null,
      currentlySelectedService: null,
      alacartSelected: true,
      cancelModal: false,
      chooseTimeModalOpen: false,
      loading: false,
      waiting: false,
      new_project: {},
      time_slots: { "Sunday": {}, "Monday": {}, "Tuesday": {}, "Wednesday": {}, "Thursday": {}, "Friday": {}, "Saturday": {} },
      location_pricing: {},
      detected_timezone: ""
    }

    this.handler = this.handler.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.setAppointmentTime = this.setAppointmentTime.bind(this)
    this.previewOrder = this.previewOrder.bind(this)
    this.cancelProject = this.cancelProject.bind(this)
    this.createRefund = this.createRefund.bind(this)
    this.createAccountCredit = this.createAccountCredit.bind(this)
    this.deleteSubService = this.deleteSubService.bind(this)
    this.onContractorSelect = this.onContractorSelect.bind(this)
    this.update = this.update.bind(this)
    this.debounceFetchContractors = debounce((service, zip, search, date, time, services, appointments, timezone) => this.props.fetchContractors(service, zip, search, date, time, services, appointments, timezone), 500)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    const {project} = this.props
    this.setState({loading: true})
    fetchServices().then(services => this.setState({ allServices: project.invoiced_at ? Object.values(services).filter(service => service.reshoot).reduce((acc, curr) => (acc[curr.id] = curr, acc), {}) : services, loading: false}))
    if (this.props.currentUser.roles.broker || project.client.role.name === "broker" || (project.client.role.name === "sub_broker" && project.client.firm?.broker_charge)) {
      fetchUserPromos(project.client.id).then(promos => this.setState({promos: Object.values(promos)}))
    }

    if (project.county != ""){
      fetchLocationSubServices(project.county).then((location_pricing => this.setState({location_pricing: location_pricing})))
    }

    let address = project.address
    let total = 0
    let package_attributes = {}
    let selectedSubservices = {}
    let previous_packages = {}
    let cancelledPackages = {}
    let appointments = {}
    let main_service = {}
    let selected_service = -1

    Object.values(project.packages).forEach(current_package => {
        previous_packages[current_package.service_id] = {
          service_id: current_package.service_id,
          id: current_package.id,
          package_sub_services: current_package.package_sub_services
        }
        package_attributes[current_package.service_id] = {}
        package_attributes[current_package.service_id]["package_sub_services_attributes"] = {}
        if (current_package.contractor) {
          appointments[current_package.service_id] = {
            service_id: current_package.service_id,
            contractor: current_package.contractor,
            appointment_time: new Date(current_package.appointment_time)
          }
        }
        package_attributes[current_package.service_id]["appointment_time"] = new Date(current_package.appointment_time)
        package_attributes[current_package.service_id]["contractor_id"] = current_package.contractor_id
        if (!current_package.contractor_id) {
          package_attributes[current_package.service_id]["appointment_time"] = null
          cancelledPackages[current_package.service_id] = true
        }
        package_attributes[current_package.service_id]["service_id"] = current_package.service_id
        package_attributes[current_package.service_id]["id"] = current_package.id

        if (selected_service === -1) {
          selected_service = current_package.service_id
        }

        if (current_package.contractor_id && !main_service[current_package.contractor_id]) {
          main_service[current_package.contractor_id] = current_package.service_id
        }

        current_package.sub_services.forEach(sub_service => {
          total += parseFloat(this.state.location_pricing[sub_service] ? this.state.location_pricing[sub_service].price : sub_service.price)
          selectedSubservices[sub_service.id] = sub_service
        })

        current_package.package_sub_services.forEach(package_sub_service => {
          package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id] = {
            sub_service_id: package_sub_service.sub_service_id,
            package_id: current_package.id,
            id: package_sub_service.id
          }
        })
    })

    let previous_total = project.promo_price || total

    if (project.promo_price){
      previous_total += project.paid_for_amount
    }

    detectTimezone(address.latitude, address.longitude).then(detected_timezone => {
        this.setState({ detected_timezone: detected_timezone })
    })

    this.setState({
      previous_packages: previous_packages,
      client: project.client_id,
      client_role: project.client.role.name,
      street: address.street1,
      county: address.county,
      firm: project.client.firm,
      apartment: address.apartment_number,
      zipcode: address.zip_code,
      state: address.region,
      city: address.city,
      latitude: address.latitude,
      longitude: address.longitude,
      formatted_address: address.formatted_address,
      package_attributes: package_attributes,
      selectedSubservices: selectedSubservices,
      cancelledPackages: cancelledPackages,
      appointments: appointments,
      main_service: main_service,
      previous_appointments: appointments,
      previous_total: previous_total
    })

    if ((project.client?.firm?.broker_charge && this.state.account_credit) || this.props.currentUser.roles.broker) {
      this.setState({ account_credit: null })
    }
  }

  anyServiceSelected() {
    return Object.keys(this.state.selectedSubservices).length > 0
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  componentDidUpdate(prevProps, prevState) {
    const {page} = this.state

    if (prevState.contractorSearch !== this.state.contractorSearch) {
      this.debounceFetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch)
    }

    if ((prevState.contractorDateSearch !== this.state.contractorDateSearch || prevState.contractorTimeSearch !== this.state.contractorTimeSearch)) {
      this.setState({ waiting: true })
      this.props.fetchContractors(this.state.selected_service, this.state.zipcode, this.state.contractorSearch, this.state.contractorDateSearch, this.state.contractorTimeSearch).then(() => { this.setState({ waiting: false }) })
    }

    if (prevState.page !== page && page === 3) {
      this.renderConfirmationModal()
    }
  }

  renderConfirmationModal() {
    const handler = (params) => {
      this.setState({subModal: null})
      this.handler(params)
    }

    const addMoreModal = <AddMoreServicesModal handleNext={this.handleNext} changeParentState={handler} />
    this.setState({addMoreModal})
  }

  cancelProject() {
    this.setState({cancelModal: true})
  }

  createRefund() {
    if (this.state.cancelModal) {
      deleteProject(this.props.project.id, "Refund").then(() => {
        this.props.history.push("/projects_index")
      })
    } else {
      this.update(true)
    }
  }

  createAccountCredit() {
    if (this.state.cancelModal) {
      deleteProject(this.props.project.id, "Coupon").then(() => {
        this.props.history.push("/projects_index")
        this.props.fetchCurrentUser(this.props.currentUser.id)
      })
    } else {
      this.update()
    }
  }

  deleteSubService(sub_service) {
    let newState = Object.assign({}, this.state.package_attributes)
    let newStateSubservices = Object.assign({}, this.state.selectedSubservices)
    let newStateAppointments = Object.assign({}, this.state.appointments)

    delete newState[sub_service.service_id]["package_sub_services_attributes"][sub_service.id]
    delete newStateSubservices[sub_service.id]

    if (Object.values(newState[sub_service.service_id]["package_sub_services_attributes"]).length === 0) {
      delete newStateAppointments[sub_service.service_id]
      delete newState[sub_service.service_id]
    }

    this.setState({
      package_attributes: newState,
      selectedSubservices: newStateSubservices,
      appointments: newStateAppointments
    })
  }

  handleNext() {

    this.setState({errors: [], subModal: null, addMoreModal: null})

    let errors = []

    if (this.state.page === 2) {
      if ((!this.anyServiceSelected() && this.state.alacartSelected) || Object.values(this.state.package_attributes).length === 0) {
        if (!this.anyServiceSelected() && this.state.alacartSelected) {
          errors.push("Please select a service before continuing")
        } else {
          errors.push("Please select at least one package for your service before continuing")
        }
      }
    }

    if (this.state.page === 4) {
      if (!this.state.appointments[this.state.selected_service]) {
        errors.push("You must select an appointment time before continuing")
      }
    }

    if (this.state.page === 5) {
      let promoPrice = null
      let promoName = ""
      let discount = 0;
      // let package_attributes_without_reshoot = Object.values(this.state.package_attributes).filter(pack => !this.state.allServices[pack.service_id].reshoot)
      let package_attributes_without_reshoot = Object.values(Object.keys(this.state.package_attributes).filter((key) => this.state.allServices[key] && !this.state.allServices[key].reshoot).reduce((cur, key) => { return Object.assign(cur, { [key]: this.state.package_attributes[key] }) }, {}))

      if (this.state.promos.length > 0) {
        this.state.promos.forEach(promo => {
          if (this.state.package_attributes[promo.service.id]) {
            if (promo.services.length > 0) {
              promo.services.forEach(service => {
                if (this.state.package_attributes[service.id] && package_attributes_without_reshoot.length === 2) {
                  promoPrice = parseInt(promo.price)
                  promoName = promo.name
                }
              })
            } else {
              if (package_attributes_without_reshoot.length === 1) {
                promoPrice = parseInt(promo.price)
                promoName = promo.name
              }
            }
          }
        })
      }

      this.previewOrder(discount, promoPrice, promoName)
      return
    }

    if (errors.length > 0) {
      this.setState({summaryErrors: errors})
    } else {
      if (this.state.page === 1) {
        this.setState({page: 0, errors: [], summaryErrors: []})
      } 
      else if (this.state.page === 4 || this.state.page === 2) {
        if (Object.values(this.state.appointments).length === Object.values(this.state.package_attributes).length) {
          this.setState({ page: this.state.page === 2 ? this.state.page + 4 : this.state.page + 1, errors: [], summaryErrors: [], propertyErrors: [], currentlySelectedService: null, selected_service: -1 })
        }
        else {
          let selected_service = "";
          let appointment_time = "";
          if (Object.values(this.state.package_attributes).length - Object.values(this.state.appointments).length > 1) {
            selected_service = parseInt(Object.keys(this.state.package_attributes).find(n => !this.state.allServices[n].twilight && !Object.keys(this.state.appointments).includes(n)))
          } else {
            selected_service = parseInt(Object.keys(this.state.package_attributes).find(n => !Object.keys(this.state.appointments).includes(n)))
          }
          if (Object.values(this.state.appointments).length > 0) {
            appointment_time = Object.values(this.state.appointments).find(n => !this.state.allServices[n.service_id].twilight)?.appointment_time ? new Date(Object.values(this.state.appointments).find(n => !this.state.allServices[n.service_id].twilight)?.appointment_time) : ""
          }

          const dateString = appointment_time ? formatDatetime(appointment_time, null, this.state.detected_timezone || this.props.project?.client?.timezone) : null

          const parsedDate = appointment_time ? moment.tz(dateString, 'MMMM D, YYYY [at] h:mm A z', railsToJsTimezoneMap[this.state.detected_timezone || this.props.project?.client?.timezone]) : null;

          let contractorDateSearch = appointment_time || "";
          let contractorTimeSearch = parsedDate ? parsedDate.hours() + (parsedDate.minutes() / 60) : ""
          if (this.state.allServices[selected_service].twilight && appointment_time) {
            const weekday = formatDatetime(appointment_time, 'dddd')
            contractorTimeSearch = Object.keys(this.state.twilight_time[weekday])[0]
          }
          this.setState({ selected_service: selected_service, waiting: true, errors: [], summaryErrors: [], propertyErrors: [], contractorDateSearch: contractorDateSearch, contractorTimeSearch: contractorTimeSearch })
          this.props.fetchContractors(selected_service, this.state.zipcode, this.state.contractorSearch, contractorDateSearch, contractorTimeSearch, Object.keys(this.state.package_attributes), Object.keys(this.state.appointments), this.state.detected_timezone).then((contractors) => {
            if (Object.values(contractors.contractors).length === 0) {
              this.setState({ contractorDateSearch: "", contractorTimeSearch: "", waiting: false })
            } else {
              this.setState({ waiting: false })
            }
          })
          if (this.state.page === 2) {
            this.setState({ page: 3 })
          }
        }
      } else {
        this.setState({page: this.state.page + 1, errors: [], summaryErrors: []})
      }
    }
  }

  handlePrevious(e) {
    e.preventDefault()

    this.setState({errors: [], subModal: null, addMoreModal: null})

    if (this.state.page === 2 && !this.state.currentlySelectedService){
      this.setState({page: 6, summaryErrors: [] })
    } else if (this.state.page === 4 && this.state.alacartSelected) {
      this.setState({ page: 2, errors: [], summaryErrors: [], propertyErrors: [], selected_service: -1, currentlySelectedService: null })
    } else if (this.state.page === 2 && this.state.currentlySelectedService) {
      this.setState({ errors: [], selected_service: -1, currentlySelectedService: null, summaryErrors: [], propertyErrors: [] }) 
    } else if (this.state.page === 5 && !this.state.currentlySelectedService){
      this.setState({page: 2})
    } else {
      this.setState({page: this.state.page - 1, summaryErrors: []})
    }
  }

  update(refund = false) {
    fetchUnavailability(Object.values(this.state.appointments), this.state.detected_timezone || this.props.project?.client?.timezone, this.state.street).then((unavailable_appointments) => {
      if (unavailable_appointments.length > 0) {
        let newStateAppointments = this.state.appointments
        let newState = this.state.package_attributes
        unavailable_appointments.forEach((unavailable) => {
          delete newStateAppointments[unavailable]
          delete newState[unavailable]["appointment_time"]
          delete newState[unavailable]["contractor_id"]
        })
        this.props.showToast("Photographer is no longer available at this appointment time")
        this.setState({package_attributes: newState, appointments: newStateAppointments, page: 3, subModal: null })
      } 
    else {
    let data = {}
    let address_attributes = {}
    let package_attributes = Object.assign({}, this.state.package_attributes)
    let price = 0;
    let discount = 0;
    let account_credit = this.state.account_credit;

    Object.values(this.state.selectedSubservices).map(subservice => {
      price += parseFloat(subservice.price)
    })

    price -= this.state.previous_total

    if (this.state.promo_price) {
      price = this.state.promo_price
    }

    if (this.state.account_credit && price > 0) {
      if (this.state.account_credit >= price) {
        account_credit = this.state.account_credit - price
        price = 0
        discount = this.state.account_credit - account_credit
      } else {
        price = price - this.state.account_credit
        discount = this.state.account_credit
        account_credit = 0
      }
    }

    Object.values(this.state.previous_packages).forEach((current_package => {
      if (!package_attributes[current_package.service_id]) {
        package_attributes[current_package.service_id] = {id: current_package.id, _destroy: true}
      } else {
        if (!package_attributes[current_package.service_id]["id"]) {
          package_attributes[current_package.service_id]["id"] = current_package.id
        }
        current_package.package_sub_services.forEach((package_sub_service) => {
          if (!package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id]) {
            package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id] = {
              id: package_sub_service.id,
              _destroy: true
            }
          } else {
            package_attributes[current_package.service_id]["package_sub_services_attributes"][package_sub_service.sub_service_id]["id"] = package_sub_service.id
          }
        })
      }
    }))

    address_attributes = {
      id: this.props.project.address.id,
      apartment_number: this.state.apartment,
      city: this.state.city,
      region: this.state.state,
      street1: this.state.street,
      zip_code: this.state.zipcode,
      formatted_address: this.state.formatted_address
    }

    if (discount > 0 && this.state.account_credit) {
      let coupon_info = { coupon_id: this.props.project?.client?.account_credit?.id, coupon_amount: parseInt(this.state.account_credit) - discount, coupon_discount_amount: discount }
      data = {
        coupon_info: coupon_info,
        refund: refund,
        discount: this.state.discount.toFixed(2),
        client_id: this.state.client,
        promo_price: this.state.promo_price,
        broker_booked: this.props.currentUser.roles.broker ? true : false,
        address_attributes: address_attributes,
        packages_attributes: Object.values(package_attributes),
        addon_added: this.props.project.addons_available
      }
    } else {
      data = {
        refund: refund,
        discount: this.state.discount.toFixed(2),
        client_id: this.state.client,
        promo_price: this.state.promo_price,
        broker_booked: this.props.currentUser.roles.broker ? true : false,
        address_attributes: address_attributes,
        packages_attributes: Object.values(package_attributes),
        addon_added: this.props.project.addons_available
      }
    }

    this.setState({errors: []})


    updateProject(data, this.props.project.id).then(project => {
      if (project.errors) {
        this.setState({summaryErrors: project.errors, subModal: null})
      } else {
        this.setState({ page: 6, subModal: null, new_project: Object.values(project)[0] })
        if (discount > 0 && this.state.account_credit){
          this.props.fetchCurrentUser(this.props.currentUser.id)
        }
      }
    })
   }})
  }

  previewOrder(discount, promo_price, promo_name) {
    const subModal = <UpdateProjectModal changeParentState={this.handler}
                                         location_pricing={this.state.location_pricing}
                                         promo_price={promo_price}
                                         client_role={this.state.client_role}
                                         promo_name={promo_name}
                                         previous_services={this.state.services}
                                         account_credit={this.state.account_credit}
                                         client={this.state.client}
                                         cancelModal={this.state.cancelModal}
                                         firm={this.state.firm}
                                         createRefund={this.createRefund}
                                         createAccountCredit={this.createAccountCredit}
                                         previous_total={this.state.previous_total}
                                         street={this.state.street}
                                         city={this.state.city}
                                         state={this.state.state}
                                         formatted_address={this.state.formatted_address}
                                         apartment={this.state.apartment}
                                         zipcode={this.state.zipcode} 
                                         discount={discount}
                                         subservices={this.state.selectedSubservices}
                                         appointments={this.state.appointments}
                                         updateProject={this.update}
                                         timezone={this.state.detected_timezone || this.props.project?.client?.timezone}/>
    this.setState({promo_price: promo_price, promo_name: promo_name, discount: discount, subModal})
  }

  setAppointmentTime(date, contractor) {
    let newState = this.state.package_attributes
    let newAppointmentState = this.state.appointments
    let newMainService = this.state.main_service
    let main = true

    Object.values(this.state.appointments).forEach(appointment => {
      if (appointment.contractor === contractor && appointment.appointment_time.toString() === date.toString()) {
        main = false
      }
    })

    if (main === true && !this.state.allServices[this.state.selected_service].twilight) {
      newMainService[contractor.id] = this.state.selected_service
    }

    newAppointmentState[this.state.selected_service] = {
      service_id: this.state.selected_service,
      contractor: contractor,
      appointment_time: date
    }

    newState[this.state.selected_service]["appointment_time"] = date
    newState[this.state.selected_service]["contractor_id"] = contractor.id
    newState[this.state.selected_service]["service_id"] = this.state.selected_service

    this.setState({
      package_attributes: newState,
      appointments: newAppointmentState,
      main_service: newMainService,
      summaryErrors: []
    })
  }

  handler(newState) {
    const currentState = {...this.state, ...newState}

    if (currentState.zipcode.length === 5 && currentState.selected_service !== -1 && newState.selected_service) {
      this.setState({waiting: true})
      this.props.fetchContractors(currentState.selected_service, currentState.zipcode, currentState.contractorSearch).then(contractors => {
        this.setState({waiting: false})
      })
    }
    this.setState(newState)
  }

  onContractorSelect(contractorId, photographer) {
    if (contractorId) {
      const subModal = <ContractorCalendar photographer={photographer}
                                           services={this.state.allServices}
                                           setAppointmentTime={this.setAppointmentTime}
                                           appointments={this.state.appointments}
                                           street={this.state.street}
                                           selectedService={this.state.selected_service}
                                           changeSelected={this.onContractorSelect}
                                           project={this.props.project}
                                           isLargeScreen={this.props.isLargeScreen}/>

      this.setState({selectedContractor: photographer, subModal})
    } else {
      this.setState({selectedContractor: null, subModal: null})
    }
  }

  content() {
    const {page} = this.state
    const {project} = this.props

    if (page === 2) {
      return <ServicesSelectionComponent changeParentState={this.handler}
                                         summaryErrors={this.state.summaryErrors}
                                         specific_service={this.state.specific_service}
                                         zipcode={this.state.zipcode}
                                         alacartSelected={true} 
                                         bundleSelected={false}
                                         page={this.state.page}
                                         cancelledPackages={this.state.cancelledPackages}
                                         selected_service={this.state.selected_service}
                                         selectedSubservices={this.state.selectedSubservices}
                                         services={this.state.allServices}
                                         package_attributes={this.state.package_attributes}
                                         appointments={this.state.appointments}
                                         previous_services={this.state.services}
                                         currentlySelectedService={this.state.currentlySelectedService}
                                         addonServices={this.props.project.addons_available}
                                         location_pricing={this.state.location_pricing}
                                         loading={this.state.loading}/>
    }

    if (page === 3) {
      return <ServicesSelectionComponent changeParentState={this.handler}
        summaryErrors={this.state.summaryErrors}
        specific_service={this.state.specific_service}
        zipcode={this.state.zipcode}
        previouslyChosen={this.state.previouslyChosen}
        alacartSelected={true}
        bundleSelected={false}
        page={this.state.page}
        cancelledPackages={this.state.cancelledPackages}
        previous_services={this.state.services}
        selected_service={this.state.selected_service}
        selectedSubservices={this.state.selectedSubservices}
        services={this.state.allServices}
        package_attributes={this.state.package_attributes}
        appointments={this.state.appointments}
        currentlySelectedService={this.state.currentlySelectedService}
        addonServices={this.props.project.addons_available}
        location_pricing={this.state.location_pricing}
        loading={this.state.loading} />
    }

    if (page === 4) {
      return <PhotographerSelectionComponent changeParentState={this.handler}
                                             detected_timezone={this.state.detected_timezone}
                                             contractorTimeSearch={this.state.contractorTimeSearch}
                                             contractorDateSearch={this.state.contractorDateSearch}
                                             summaryErrors={this.state.summaryErrors}
                                             included_services={this.state.allServices}
                                             main_service={this.state.main_service}
                                             zipcode={this.state.zipcode}
                                             street={this.state.street}
                                             handleNext={this.handleNext}
                                             services={this.state.package_attributes}
                                             project={this.props.project}
                                             setAppointmentTime={this.setAppointmentTime}
                                             selectedService={this.state.selected_service}
                                             contractors={this.state.waiting ? {} : this.props.contractors}
                                             waiting={this.state.waiting}
                                             appointments={this.state.appointments}
                                             isLargeScreen={this.props.isLargeScreen}
                                             previouslyChosen={this.state.previouslyChosen}
                                             createProjectVersion2={true}
                                             page={this.state.page}
                                             onContractorSelect={this.onContractorSelect}/>
    }

    if (page === 5) {
      return <>
        <OrderSummaryComponent changeParentState={this.handler} checkout={true}
                              location_pricing={this.state.location_pricing}
                               page={this.state.page}
                               services={this.state.allServices}
                               previous_total={this.state.previous_total}
                               errors={this.state.summaryErrors}
                               promos={this.state.promos}
                               package_attributes={this.state.package_attributes}
                               deleteSubService={this.deleteSubService}
                               account_credit={this.state.account_credit}
                               subservices={this.state.selectedSubservices}
                               appointments={this.state.appointments}
                               previous_services={this.state.services}
                               previewOrder={this.previewOrder}
                               currentUser={this.props.currentUser}
                               cancelProject={this.cancelProject}
                               street={this.state.street}
                               city={this.state.city}
                               formatted_address={this.state.formatted_address}
                               state={this.state.state}
                               apartment={this.state.apartment}
                               detected_timezone={this.state.detected_timezone || this.props.project?.client?.timezone}
                               zipcode={this.state.zipcode}/></>
    }

    if (page === 6) {
      return <ProjectUpdatedModal project={project} new_project={this.state.new_project} changeParentState={this.handler}/>
    }
  }

  progress() {
    const {page} = this.state
    const step = page - 1

    return [1, 2, 3, 4, 5].map(i => {
      return <div className={`modalFooterProgressItem ${step >= i ? 'completed' : ''}`} key={`step-${i}`}>
        {i}
      </div>
    })
  }

  isNextButtonDisabled() {
    if (this.state.page === 5) {
      if (Object.values(this.state.selectedSubservices).length === 0) return true
    }

    return false
  }

  onClose() {
    if (this.state.page === 6) {
      location.reload()
    } else {
      this.props.onClose()
    }
  }

  calculatePrice() {
    let price = 0

    let previous_services = {}
    this.state.services.forEach(service => previous_services[service.id] = true)

    Object.values(this.state.selectedSubservices).filter(sub_service => !previous_services[sub_service.service_id]).forEach(subservice => {
      price += parseInt(this.state.location_pricing[subservice.id] ? this.state.location_pricing[subservice.id].price : subservice.price)
    })

    if (this.state.discount){
      price -= this.state.discount.toFixed(2)
    }

    return price
  }

  render() {
    const {page, subModal, chooseTimeModalOpen, addMoreModal} = this.state

    if (subModal) {
      return subModal
    }

    let price = this.calculatePrice()

    if (chooseTimeModalOpen){
      return <ChooseTimeModal changeParentState={this.handler} detected_timezone={this.state.detected_timezone || this.props.project?.client?.timezone} currentUser={this.props.currentUser} selectedService={this.state.selected_service} appointments={this.state.appointments} package_attributes={this.state.package_attributes} time_slots={this.state.time_slots} contractorDateSearch={this.state.contractorDateSearch} contractorTimeSearch={this.state.contractorTimeSearch} />
    }

    let previous_services = {}

    this.state.services.forEach(service => previous_services[service.id] = true)

    let more_services_added = Object.values(this.state.appointments).filter(appointment => !previous_services[appointment.service_id]).length > 0

    return <div className="modal-background">
      <div className={`orderpreviewModal addServiceModal`}>
        <div className="orderPreviewModalComponent">
          <div className={this.state.page === 5 ? "" : "addServiceModalContent"}>
            {this.state.page === 6 ? "" : <button className="projectEditButton projectEditButtonLight modalCloseButton modalCloseButtonHomepage" onClick={() => this.onClose()}>
              Close
            </button>}
            {this.state.page === 6 ? "" : <div className="closeIcon modalCloseButtonHomepageMobile" onClick={() => this.onClose()}><i className="fas fa-times" /></div>}
            {addMoreModal}
            {this.content()}
          </div>

          {page !== 6 && <div className="modalFooter">
            {(this.state.page === 2 || this.state.page === 3 || this.state.page === 4) && <div className="homepageModalTotalMobile">Total: ${price}</div>}
            <div className="modalFooterProgress">
              {this.progress()}
            </div>
            {(this.state.page === 2 || this.state.page === 3 || this.state.page === 4) && <div className="homepageModalTotal">Total: ${price}</div>}
            <div className="modalFooterButtons">
              <button className={`projectEditButton projectEditButtonPrimary ${page === 2 && !more_services_added && !this.state.currentlySelectedService || (page === 2 && more_services_added && !this.state.currentlySelectedService && (Object.values(this.state.appointments).length - Object.values(this.state.previous_appointments).length) != (Object.values(this.state.package_attributes).length - Object.values(this.state.previous_packages).length)) ? 'grayedoutButton' : ''}`}
                onClick={page === 2 && !more_services_added && !this.state.currentlySelectedService || (page === 2 && more_services_added && !this.state.currentlySelectedService && (Object.values(this.state.appointments).length - Object.values(this.state.previous_appointments).length) != (Object.values(this.state.package_attributes).length - Object.values(this.state.previous_packages).length)) ? "" : this.handlePrevious}>
                {page === 2 && more_services_added && !this.state.currentlySelectedService ? "Order Summary" : "Previous"}
              </button>
              <button className="projectEditButton projectEditButtonPrimary" onClick={this.handleNext} disabled={this.isNextButtonDisabled()}>
                {page === 5 ? 'Checkout' : 'Next Step'}
              </button>
            </div>
          </div>}
        </div>
        <div className="modalMobilePushup"></div>
      </div>
    </div>
  }
}

export default AddServiceModal;
