import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import UserAvatar from "../photographers/photograher_avatar";
import {formatDatetime} from "../../util/date_util";

class OrderSummaryComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }


  componentDidMount() {
  }

  openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    let price = 0;
    let discount = 0;
    let button = <button className="projectEditButton projectEditButtonPrimary" onClick={() => this.props.previewOrder(discount, promoPrice, promoName)}>Submit Changes</button>
    let account_credit_component = "";
    let account_credit = this.props.account_credit;

    let promoPrice = null
    let promoName = ""
    let previous_services = {}
    // let package_attributes_without_reshoot = Object.values(this.props.package_attributes).filter(pack => !this.props.services[pack.service_id].reshoot)
    let package_attributes_without_reshoot = Object.values(Object.keys(this.props.package_attributes).filter((key) => this.props.services[key] && !this.props.services[key].reshoot).reduce((cur, key) => { return Object.assign(cur, { [key]: this.props.package_attributes[key] }) }, {}))

    this.props.previous_services.forEach(service => previous_services[service.id] = true)

    if (this.props.promos.length > 0) {
      this.props.promos.forEach(promo => {
        if (this.props.package_attributes[promo.service.id]) {
          if (promo.services.length > 0) {
            promo.services.forEach(service => {
              if (this.props.package_attributes[service.id] && package_attributes_without_reshoot.length === 2) {
                promoPrice = parseInt(promo.price)
                promoName = promo.name
              }
            })
          } else {
            if (package_attributes_without_reshoot.length === 1) {
              promoPrice = parseInt(promo.price)
              promoName = promo.name
            }
          }
        }
      })
    }


    let subServices = Object.values(this.props.subservices).map(subservice => {

      price += parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price)

      if (previous_services[subservice.service_id]){
        return ""
      } else {
      return (<div className="orderSummaryLi">
        <div>{this.props.services[subservice.service_id]?.title} - {subservice.title}</div>
        <div style={{display: "flex"}}>
          <div className="orderSummarySubservicePrice">${parseFloat(this.props.location_pricing[subservice] ? this.props.location_pricing[subservice].price : subservice.price).toFixed(2)}</div>
          {this.props.checkout ? <div className="itemIcon3" onClick={() => this.props.changeParentState({ page: 2, specific_service: subservice.service_id, selected_service: subservice.service_id })}><i className="fas fa-edit settingsServiceIcon m-2"></i></div> : ""}
          <div className="itemIcon" onClick={() => this.props.deleteSubService(subservice)}><i className="far fa-trash-alt settingsServiceIcon m-2"></i></div>
        </div>
      </div>)
      }
    })

    price -= parseInt(this.props.previous_total)


    if (promoPrice) {
      let promo_discount = price - (promoPrice - parseInt(this.props.previous_total))
      subServices.push(<div className="orderSummaryLi">
        <div>{promoName} Promo Applied: </div>
        <div className="orderSummarySubservicePrice promoApplied">{promo_discount > 0 ? "-" : ""}${Math.abs(promo_discount).toFixed(2)}</div>
      </div>)

      price = promoPrice - parseInt(this.props.previous_total)
    }

    if (this.props.account_credit && price > 0) {
      if (this.props.account_credit >= price) {
        account_credit = this.props.account_credit - price
        price = 0
        discount = this.props.account_credit - account_credit
      } else {
        price = price - this.props.account_credit
        discount = this.props.account_credit
        account_credit = 0
      }
    }

    if (discount > 0) {
      subServices.push(<div className="orderSummaryLi">
        <div>Account credit applied: </div>
        <div className="orderSummarySubservicePrice promoApplied">-${discount.toFixed(2)}</div>
      </div>)
    }

    if (account_credit > 0) {
      account_credit_component = <div className="">
        <div className="totalOrderSummaryDiv">Account Credit:</div>
        <div className="totalOrderSummaryPrice">$ {account_credit.toFixed(2)}</div>
      </div>
    }

    let photographersLis = Object.values(this.props.appointments).filter(appointment => !previous_services[appointment.service_id]).map(appointment => (
      <div className="orderSummaryPhotographerLi">
        <UserAvatar user={appointment.contractor} onClick={() => this.openInNewTab(window.location.origin + `/photographers/${appointment.contractor.id}`)} />
        <div className="contractorNameAndAppointment">
          <div>{appointment.contractor.firstName} {appointment.contractor.lastName[0]}</div>
          {/* <div dangerouslySetInnerHTML={{ __html: this.props.services[appointment.service_id].black_icon }}></div> */}
          <div>{this.props.services[appointment.service_id]?.title}</div>
          <div>{formatDatetime(appointment.appointment_time, null, this.props.detected_timezone)}</div>
          {this.props.checkout ? <div className="changeAppointmentText" onClick={() => this.props.changeParentState({page: 3, selected_service: appointment.service_id})}>Change Appointment</div> : ""}
        </div>
      </div>
    ))

    if (photographersLis.length === 0) {
      photographersLis =
        <div className="noServicesSelected">
          <div>No selected photographers</div>
        </div>
    }

    let errors = []

    this.props.errors?.forEach((error) => {
      errors.push(<li>{error}</li>)
    })

    let checkout = ""

    if (this.props.checkout) {
      checkout = "checkoutStyle"
    }

    return (
      <div id={checkout} className="orderSummaryComponent">
        <div className="settingsComponentHeader">
          <div className="settingsComponentHeading">Check Out</div>
          <div className="settingsComponentDescription">
            Below is a list of your added services
          </div>
        </div>
        <div className="subServicesList">
          {subServices}
          {this.props.checkout ? <div className="addMoreServicesText" onClick={() => this.props.changeParentState({ page: 2, errors: [], selected_service: -1, specific_service: -1 })}>Add More Services</div> : ""}
        </div>
        <div className="settingsComponentHeading">Photographers</div>
        <div className="photographersList">
          {photographersLis}
        </div>
        <div className="orderSummaryPriceDiv">
          <div>Change in cost: </div>
          <div>${price.toFixed(2)}</div>
        </div>
        {/* {account_credit_component} */}
        <ul className="backendErrorUl">{errors}</ul>
      </div>
    )
  }
}

export default withRouter(OrderSummaryComponent);