import React from "react";
import { Route, Link, withRouter } from "react-router-dom";
import ContractorsList from "./contractors_list";
import checkmarkCreateProject from "../../../../assets/images/checkmarkCreateProject"
import LogoSpinner from "../../../../assets/images/landing/video-thumbnails/MA-logo-spinner.gif";
import { fetchTimeSlots } from "../../util/settings_api_util";
import {formatDatetime} from "../../util/date_util";
import { railsToJsTimezoneMap } from "../../util/timezone_utils";
import moment from 'moment'
import 'moment-timezone';

class PhotographySelectionComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minimize: this.props.minimize,
    }
    this.onContractorSelect = this.onContractorSelect.bind(this)
    this.onContractorTimeChange = this.onContractorTimeChange.bind(this)
  }


  componentDidMount() {
    if (!this.props.minimize){
      let twilight = this.props.included_services[this.props.selectedService].twilight
      fetchTimeSlots(twilight, this.props.detected_timezone).then(time_slots =>
        this.props.changeParentState({ time_slots: time_slots, contractorSearch: "" }
      ))
     }
  }

  onSearchChange(event) {
    this.props.changeParentState({contractorSearch: event.target.value})
  }

  onContractorSelect(contractor_id, photographer){
    let appointment_time = this.props.contractorDateSearch
    appointment_time.setHours(parseInt(this.props.contractorTimeSearch))
    appointment_time.setMinutes((this.props.contractorTimeSearch % 1) * 60)
    let timezone = this.props.detected_timezone ? railsToJsTimezoneMap[this.props.detected_timezone] : this.props.client_timezone ? railsToJsTimezoneMap[this.props.client_timezone] : railsToJsTimezoneMap[this.props.currentUser.timezone]
    if (this.props.project && !this.props.detected_timezone){
      timezone = railsToJsTimezoneMap[this.props.project?.client?.timezone]
    }

    let timeInTargetTimezone = moment.tz({ year: appointment_time.getFullYear(), month: appointment_time.getMonth(), day: appointment_time.getDate(), hour: parseInt(this.props.contractorTimeSearch), minute: (this.props.contractorTimeSearch % 1) * 60 }, timezone);

    let timeInLocalTimezone = timeInTargetTimezone.clone().tz(moment.tz.guess());

    let dateWithTimeZone = timeInLocalTimezone.toDate();

    this.props.setAppointmentTime(dateWithTimeZone, photographer)
  }

  onContractorTimeChange(){
    if (this.props.reschedulingContractorCancel){
      this.props.changeParentState({ contractorDateSearch: "", contractorTimeSearch: "" })
    } else {
    let newStateAppointments = this.props.appointments
    let newState = this.props.services
    delete newStateAppointments[this.props.selectedService]
    delete newState[this.props.selectedService]["appointment_time"]
    delete newState[this.props.selectedService]["contractor_id"]
    this.props.changeParentState({ appointments: newStateAppointments, package_attributes: newState, contractorDateSearch: "", contractorTimeSearch: "" })
    }
  }

  render() {
    let component = ""

    if (!this.props.appointments[this.props.selectedService] && this.props.services[this.props.selectedService] && Object.values(this.props.services[this.props.selectedService]["package_sub_services_attributes"]).length > 0) {
      Object.values(this.props.appointments).forEach(appointment => {
        if (this.props.contractors[appointment.contractor?.id] && appointment.appointment_time > new Date() && appointment.service_id != this.props.selectedService && !this.props.included_services[appointment.service_id].twilight && !this.props.included_services[this.props.selectedService].twilight) {
          let contractor = this.props.contractors[appointment.contractor?.id]
          this.props.setAppointmentTime(appointment.appointment_time, contractor)
          let newState = this.props.previouslyChosen
          newState[this.props.selectedService] = contractor?.id
          this.props.changeParentState({previouslyChosen: newState})
        }
      })
    }


    if (this.props.appointments[this.props.selectedService] ){
      let main = true;

      Object.values(this.props.appointments).forEach(appointment => {
        Object.values(this.props.contractors).forEach(contractor => {
          if (appointment.contractor?.id === contractor?.id && appointment.service_id != this.props.selectedService) {
            main = false;
          }
        })
      })

      if (main === true && this.props.previouslyChosen && this.props.previouslyChosen[this.props.selectedService]){
        let newState = this.props.previouslyChosen
        delete newState[this.props.selectedService]
        this.props.changeParentState({ previouslyChosen: newState })
      }
    }


    let searchShown = "searchHidden"

    if(this.props.waiting){
     component =  <div className="createProjectLogoSpinner">
        <img className="logoImageSpinnerGif" src={LogoSpinner}></img>
      </div>
    } else if (this.props.previouslyChosen && this.props.previouslyChosen[this.props.selectedService] && !this.state.minimize){
      if (this.props.createProjectHomepage){
        this.props.changeParentState({ page: this.props.page + 2 })
      } else if (this.props.createProjectVersion2){
        this.props.changeParentState({ page: this.props.page + 1})
      }
      component = <div className="noPhotographerMessage">Your photographer and appointment time was pre selected because your previously selected photographer can do this service as well.</div>
    } else if (this.props.appointments[this.props.selectedService]?.contractor){
      searchShown = ""
      let contractors = {[this.props.appointments[this.props.selectedService].contractor?.id]: this.props.appointments[this.props.selectedService].contractor}
      component = <ContractorsList changeParentState={this.handler}
        changeGrandparentState={this.props.changeParentState}
        contractorDateSearch={this.props.contractorDateSearch}
        detected_timezone={this.props.detected_timezone}
        client_timezone={this.props.client_timezone}
        services={this.props.services}
        handleNext={this.props.handleNext}
        project={this.props.project}
        street={this.props.street}
        setAppointmentTime={this.props.setAppointmentTime}
        contractors={contractors}
        appointments={this.props.appointments}
        selectedService={this.props.selectedService}
        onContractorSelect={this.props.contractorDateSearch ? this.onContractorSelect : this.props.onContractorSelect}
        isLargeScreen={this.props.isLargeScreen} />
    } else if (this.state.selected !== -1 && !this.state.minimize && this.props.zipcode !== "" && this.props.services && this.props.services[this.props.selectedService] && this.props.services[this.props.selectedService]["package_sub_services_attributes"] && Object.values(this.props.services[this.props.selectedService]["package_sub_services_attributes"]).length > 0) {
      searchShown = ""
      component = <ContractorsList changeParentState={this.handler}
                                   changeGrandparentState={this.props.changeParentState}
                                   contractorDateSearch={this.props.contractorDateSearch}
                                   services={this.props.services}
                                   street={this.props.street}
                                   handleNext={this.props.handleNext}
                                   project={this.props.project}
                                   detected_timezone={this.props.detected_timezone}
                                   client_timezone={this.props.client_timezone}
                                   setAppointmentTime={this.props.setAppointmentTime}
                                   contractors={this.props.contractors}
                                   appointments={this.props.appointments}
                                   selectedService={this.props.selectedService}
                                   onContractorSelect={this.props.contractorDateSearch ? this.onContractorSelect : this.props.onContractorSelect}
                                   isLargeScreen={this.props.isLargeScreen}/>
    } else if (this.props.reschedulingContractorCancel){
      searchShown = ""
      component = <ContractorsList changeParentState={this.handler}
        changeGrandparentState={this.props.changeParentState}
        services={this.props.services}
        setAppointmentTime={this.props.setAppointmentTime}
        contractor={this.props.contractor}
        appointment_time={this.props.appointment_time}
        detected_timezone={this.props.detected_timezone}
        contractorDateSearch={this.props.contractorDateSearch}
        client_timezone={this.props.client_timezone}
        project={this.props.project}
        contractors={this.props.contractors}
        street={this.props.street}
        appointments={this.props.appointments}
        selectedService={this.props.selectedService}
        onContractorSelect={this.props.contractorDateSearch ? this.onContractorSelect : this.props.onContractorSelect}
        isLargeScreen={this.props.isLargeScreen}
        updating={this.props.updating}
        reschedulingContractorCancel={this.props.reschedulingContractorCancel}
         />
    }else {
      component = <div className="noPhotographerMessage">In order for photographers to appear, at least one option must be selected and a zipcode must be provided.</div>
    }

    let icon = <div key="1"><i className="fas fa-window-minimize"/></div>
    let minimizeId = ""
    let minimized = ""
    let checkmark = ""

    if (this.state.minimize) {
      icon = this.props.page >= 3 ? <div key="2"><i className="fas fa-plus"></i></div> : ""
      minimizeId = "hidePropertyInputs"
      minimized = "projectHeadingMinmizedView"

      if (this.props.page && this.props.page > 3) {
        checkmark = "createProjectCheckmarkShow"
      }

    }

    let appointment_time = ""

    if (this.props.contractorDateSearch){
      let new_appointment_time = this.props.contractorDateSearch
      new_appointment_time.setHours(parseInt(this.props.contractorTimeSearch))
      new_appointment_time.setMinutes((this.props.contractorTimeSearch % 1) * 60)
      let timezone = this.props.detected_timezone ? railsToJsTimezoneMap[this.props.detected_timezone] : this.props.client_timezone ? railsToJsTimezoneMap[this.props.client_timezone] : railsToJsTimezoneMap[this.props.currentUser.timezone]
      if (this.props.project && !this.props.detected_timezone) {
        timezone = railsToJsTimezoneMap[this.props.project?.client?.timezone]
      }
      let timeInTargetTimezone = moment.tz({ year: new_appointment_time.getFullYear(), month: new_appointment_time.getMonth(), day: new_appointment_time.getDate(), hour: parseInt(this.props.contractorTimeSearch), minute: (this.props.contractorTimeSearch % 1) * 60 }, timezone);

      // Convert the time to the local timezone
      let timeInLocalTimezone = timeInTargetTimezone.clone().tz(moment.tz.guess());

      // Convert the moment object to a standard JavaScript Date object
      appointment_time = timeInLocalTimezone.toDate();
    }

    if (this.props.reschedulingContractorCancel){
      return (
        <div className="modal-background" onClick={() => this.props.changeParentState({ photographerModalOpen: false, contractor: {}, appointment_time: "" })}>
          <div className="orderpreviewModal contractorSchedulingModal" onClick={e => e.stopPropagation()}>
          <div className="orderPreviewModalComponent" id="rescheduleModalComponent">
              <div className="projectLocationHeadingMinimizedGrouping">
                <div className="settingsComponentHeading">Choose a Photographer</div>
              </div>
            <div className="photographerSubheading photographerSubheadingTop" id={minimizeId}>Clicking on a photographer will bring up a calendar allowing you to coordinate timing.</div>
            <div className="photographerButtonDiv">
              <div className="searchInput" id={searchShown}>
                <input className="formInput" onChange={e => this.onSearchChange(e)} placeholder="Search" />
              </div>
              <button className="chooseTimeModalButton" id={searchShown} onClick={() => this.props.changeParentState({ chooseTimeModalOpen: true })}>{appointment_time ? <div>{formatDatetime(appointment_time, null, this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone)}</div> : "Choose Date and Time"}</button>
              <button className="chooseTimeModalButtonLight" id={searchShown} onClick={this.onContractorTimeChange}>Reset Time</button>
            </div>
            <div className="errorDiv" id={minimizeId}>{this.props.summaryErrors}</div>
            <div id={minimizeId}>
              {component}

              {Object.values(this.props.contractors).length === 0 && !this.props.waiting && (
                <div className="photographersCardDescription">
                  Oops, there aren't any photographers available for these selections.
                </div>)}
            </div>
              <div className="signupFormButtons">
                <div className="signupFormPrevious" onClick={() => this.props.changeParentState({ photographerModalOpen: false, contractor: {}, appointment_time: "" })}>Cancel</div>
                <div className="signupFormNext" onClick={() => this.props.onSubmit(this.props.appointment_time, this.props.contractor)}>Submit</div>
              </div>
           </div>
            <div className="modalMobilePushup"></div>
         </div>
       </div>
      )
    } else {
      return (
        <div className="projectLocationComponent projectPhotographerComponent">
          <div className="projectLocationHeadingGrouping" id={minimized}>
            <div className="projectLocationHeadingMinimizedGrouping">
              <div className="createProjectCheckmark" id={checkmark}><img src={checkmarkCreateProject} /></div>
              {/* <div className="settingsComponentHeading">{this.state.minimize ? "Choose a Photographer" : `Choose a photographer for ${this.props.included_services[this.props.selectedService]?.title}`}</div> */}
              <div className="settingsComponentHeading serviceChoiceHeading">{this.state.minimize ? "Choose a Photographer" : Object.values(this.props.appointments).filter(appointment => appointment.appointment_time != this.props.appointments[this.props.selectedService]?.appointment_time).length > 0 || Object.values(this.props.services).length === 1 ? `Choose a photographer for ${this.props.included_services[this.props.selectedService]?.title}` : `Choose a photographer for your services`}</div>
            </div>
            {/* <div className="minimizeButton" onClick={() => this.setState({ minimize: !this.state.minimize })}>{icon}</div> */}
          </div>

          {/* <div className="subServicesHeadingBottomDivider" id={minimizeId} /> */}

          {this.props.createProjectVersion2 && Object.values(this.props.services).length > 1 && <div className="photographerSubheading photographerSubheadingTop" id={minimizeId}>All ordered services this photographer can do will be selected.</div>}
          {!this.props.createProjectVersion2 && <div className="photographerSubheading photographerSubheadingTop" id={minimizeId}>Clicking on a photographer will bring up a calendar allowing you to coordinate timing.</div>}
          <div className={this.state.minimize ? "" : "photographerButtonDiv"} id={minimized}>
            <div className="searchInput" id={searchShown}>
              <input className="formInput" onChange={e => this.onSearchChange(e)} placeholder="Search" />
            </div>
            <button className="chooseTimeModalButton" id={searchShown} onClick={() => this.props.changeParentState({ chooseTimeModalOpen: true })}>{appointment_time ? <div>{formatDatetime(appointment_time, null, this.props.detected_timezone || this.props.client_timezone || this.props.project?.client?.timezone)}</div> : "Choose Date and Time"}</button>
            <button className="chooseTimeModalButtonLight" id={searchShown} onClick={this.onContractorTimeChange}>Reset Time</button>
          </div>
          <div className="errorDiv" id={minimizeId}>{this.props.summaryErrors}</div>
          <div id={minimizeId}>
            {component}

            {Object.values(this.props.contractors).length === 0 && !this.props.waiting && (
              this.props.contractorDateSearch ? <div className="photographersCardDescription">
                Oops, looks like there are no photographers available for your current selections.
              </div> : <div className="photographersCardDescription">
                Oops, there aren't any photographers servicing the zip code you entered.
                Our team has been alerted! Please email <a href="mailto:info@modernangles.com">info@modernangles.com</a> for immediate assistance.
              </div>)}
          </div>
        </div>
      )
    }
  }
}

export default withRouter(PhotographySelectionComponent);