import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Modal from './modal';
import InvoicesContainer from './invoices_index';
import LoginContainer from './login_container';
import ForgotPasswordContainer from './forgot_password_container';
import ForgotPasswordUpdateContainer from './forgot_password_update_container';
import { AuthRoute, ProtectedRoute } from '../util/auth_route_util';
import ConfirmationContainer from './confirmation_container';
import ConfirmationPathContainer from './confirmation_path_container';
import DashboardContainer from './dashboard_container';
import ProjectsIndexContainer from './projects_index_container';
import SettingsContainer from './settings_container';
import MediaShareContainer from "./edit_project/media_share_container";
import MediaAccessContainer from "./edit_project/media_access_container";
import SettingsMobileContainer from './settings_mobile_container';
import CreateProject2Container from './create_project_2_container';
import CreateVirtualSuiteContainer from "./create_virtual_suite_container";
import EditProjectContainer from './edit_project_container';
import PropertyTourContainer from './property_tour_container';
import PropertyTourEditContainer from './property_tour_edit_container';
import PhotographersIndexContainer from './photographers_index_container';
import PhotographerShowContainer from './photographer_show_container';
import ContactsContainer from './contacts_container';
import PayoutsContainer from "./payouts_index"
import MLSVideoShareContainer from "./edit_project/mls_video_share_container"
import { HelmetProvider } from 'react-helmet-async';

import { PUBLIC_PAGES } from '../routes';
import { PublicRouteContainer } from '../containers';
import Toasts from "./toasts/toasts";
import '../util/api_utils_setup';
import CareersApply from "./careers_apply/careers_apply";
import Referrals from "./referrals/referrals_index";
import AdModal from "./pages/adModal/AdModal";
import CreateBlogContainer from "./blog/create_blog_container";
import UpdateBlogContainer from "./blog/update_blog_container";
import {PhotographerOnboarding} from "./photographer_onboarding/photographer_onboarding";
import {ScheduleDemo} from "./schedule_demo/schedule_demo";
import BookPage from "./pages/subdomain/Book";
import MediaUploadModal from "./upload_media_modal/media_upload_modal";

const App = () => (
  <HelmetProvider>
    <div className='appMainDiv'>
      <Modal/>
      <Toasts/>
      <MediaUploadModal />

      <AdModal pagesToShowOn={[...PUBLIC_PAGES, { index: 22, exact: true, path: "/book", show_two_modals: true }, { index: 23, exact: true, path: "/book-shoot", book_shoot_page: true, show_two_modals: true }]} />
          <Switch>
            {PUBLIC_PAGES.map((route) => {
              return (
                <PublicRouteContainer
                  key={route.index}
                  exact={route.exact}
                  path={route.path}
                  component={route.page}
                  navbarDisableWaveBackground={route.navbarDisableWaveBackground}
                  navbarHideMiddleButtons={route.navbarHideMiddleButtons}
                />
              );
            })}
          </Switch>

        <Route exact path='/photographer-onboarding' component={PhotographerOnboarding} />
        <Route exact path='/schedule-demo' component={ScheduleDemo} />
        <Route exact path='/book-shoot' component={BookPage} />
        <Route exact path='/book' component={BookPage} />
        <ProtectedRoute exact path='/projects_index/:id' component={EditProjectContainer}/>
        <ProtectedRoute exact path='/create-blog' component={CreateBlogContainer} />
        <ProtectedRoute exact path='/blog/:id/update' component={UpdateBlogContainer} />
        <ProtectedRoute exact path='/projects_index/:id/review/:contractor_id/:star_rating' component={EditProjectContainer} />
        <ProtectedRoute exact path='/projects_index' component={ProjectsIndexContainer} />
        <ProtectedRoute exact path='/dashboard' component={DashboardContainer} />
        <ProtectedRoute exact path='/settings' component={SettingsContainer} />
        <ProtectedRoute exact path='/settings/:tab' component={SettingsContainer} />
        <ProtectedRoute exact path='/invoices_index' component={InvoicesContainer} />
        <ProtectedRoute exact path='/referral' component={Referrals} />
        <ProtectedRoute exact path='/payouts' component={PayoutsContainer} />
        <ProtectedRoute exact path='/support' component={ContactsContainer} />
        <ProtectedRoute
          exact
          path='/settings-menu'
          component={SettingsMobileContainer}
        />
        <ProtectedRoute
          exact
          path='/projects_index/:id/listing-website/edit'
          component={PropertyTourEditContainer}
        />
        <Route
          exact
          path='/projects_index/:id/listing-website'
          render={() => <PropertyTourContainer branded={true} />}
        />
        <Route
          exact
          path='/projects_index/:id/listing-website/unbranded'
          render={() => <PropertyTourContainer branded={false} />}
        />
        <ProtectedRoute
          exact
          path='/photographers'
          component={PhotographersIndexContainer}
        />
        <Route
          exact
          path='/photographers/:id'
          component={PhotographerShowContainer}
        />
        <ProtectedRoute exact path='/create-project' component={CreateProject2Container} />
        {/* <ProtectedRoute exact path='/create-project-2' component={CreateProjectContainer} /> */}
        <ProtectedRoute exact path='/create-virtual-suite' component={CreateVirtualSuiteContainer} />
        <Route exact path='/careers/apply' component={CareersApply} />
        <Route exact path='/login' component={LoginContainer} />
        {/* <AuthRoute exact path='/signup' component={SignupContainer} /> */}
        <AuthRoute exact path='/forgot' component={ForgotPasswordContainer} />
        <AuthRoute exact path='/resend-confirmation' component={ConfirmationContainer} />
        <AuthRoute
          path='/password/edit/:tokenId'
          component={ForgotPasswordUpdateContainer}
        />
        <AuthRoute
          path='/login/confirmation/:confirmationToken'
          component={ConfirmationPathContainer}
        />
      <Route exact path='/projects_index/:id/share/:shareCode' component={MediaShareContainer} />
      <Route exact path='/mls/:mls_code/:media_id' component={MLSVideoShareContainer} />
      <Route exact path='/projects_index/:id/access/:accessCode' component={MediaAccessContainer} />
    </div>
  </HelmetProvider>
);

export default App;
